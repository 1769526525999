import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box, Button, Divider, Grid, useTheme, LinearProgress } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from "../Assets/eventegration.png";
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

const FormResponseComponent = forwardRef(({
    response,
    featuresList,
    handleReset,
    handleClose,
    fbShareLink,
    twitterShareLink,
    linkedinShareLink,
    whatsappShareLink,
    isSmallScreen,
    eventId,
    role
}, responseBoxRef) => {
    const navigate = useNavigate();

    const [login, { loading, error }] = useMutation(gql`
            mutation ($eventId: String!, $role: String!, $email: String!, $password: String!) {
                token: loginv2(eventId: $eventId, role: $role, email: $email, password: $password)
            }
        `);

    const handleNavigate = () => {
        const variables = {eventId, role, email: response?.email, password: response?.password};
        login({ variables }).then((resp) => {
            console.log(resp);
            localStorage.setItem("auth.app.eventegration.com", resp.data.token);
            if (error) {
                navigate(`/login/${eventId}/${role}`);
            }
            navigate("/");
        });
    }
    const theme = useTheme();
    console.log("response", response)
    if(loading) return <LinearProgress />
    return (
        <Stack spacing={1} ref={responseBoxRef}>
            <Typography>
                <div
                    dangerouslySetInnerHTML={{ __html: response?.response }}
                    style={{ whiteSpace: "pre-line" }}
                />
            </Typography>
            <Box height={30} />
            <Stack direction="column" spacing={2}>
                {!featuresList?.includes("Enable Another Registration Button") ? (
                    ""
                ) : (
                    <Box justifyContent={"center"} display="flex" width="100%">
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            color="error"
                            size="large"
                        >
                            Another registration?
                        </Button>
                    </Box>
                )}

                {!featuresList.includes("ShareButton") ? (
                    ""
                ) : (
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        flexWrap={"wrap"}
                        m={2}
                        divider={
                            <Divider
                                orientation={isSmallScreen ? "horizontal" : "vertical"}
                                flexItem
                            />
                        }
                        spacing={2}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    startIcon={<FacebookIcon />}
                                    onClick={handleClose}
                                    component="a"
                                    href={fbShareLink}
                                    target="_blank"
                                    size="large"
                                    sx={{
                                        backgroundColor: "#1877F2",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#145db2" },
                                    }}
                                >
                                    Share on Facebook
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    startIcon={<TwitterIcon />}
                                    onClick={handleClose}
                                    component="a"
                                    href={twitterShareLink}
                                    target="_blank"
                                    size="large"
                                    rel="noopener noreferrer"
                                    sx={{
                                        backgroundColor: "#1DA1F2",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#1a8acb" },
                                    }}
                                >
                                    Share on Twitter
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    startIcon={<LinkedInIcon />}
                                    onClick={handleClose}
                                    component="a"
                                    href={linkedinShareLink}
                                    target="_blank"
                                    size="large"
                                    rel="noopener noreferrer"
                                    sx={{
                                        backgroundColor: "#0A66C2",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#084a82" },
                                    }}
                                >
                                    Share on LinkedIn
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    startIcon={<WhatsAppIcon />}
                                    onClick={handleClose}
                                    component="a"
                                    href={whatsappShareLink}
                                    target="_blank"
                                    size="large"
                                    rel="noopener noreferrer"
                                    sx={{
                                        backgroundColor: "#25D366",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#1ca851" },
                                    }}
                                >
                                    Share on WhatsApp
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
            </Stack>
            <Box justifyContent={"center"} display="flex" width="100%" my={2}>
                <Button
                    onClick={handleNavigate}
                    variant="contained"
                    color="success"
                    size="large"
                >
                    Take me to Dashboard
                </Button>
            </Box>
            <Stack px={4} mt={6} direction={"column"} justifyContent={"end"}>
                <Typography align="center" color={theme.palette.text.secondary} fontSize={10} gutterBottom>
                    Powered by...
                </Typography>
                <Stack direction={"row"} justifyContent={"center"}>
                    <Box
                        width={isSmallScreen ? 100 : 150}
                        sx={{ backgroundColor: "transparent", borderRadius: 1, cursor: "pointer" }}
                        onClick={() => {
                            window.location.href = "https://eventegration.com";
                        }}
                    >
                        <img src={logo} width="95%" alt="Logo" />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
});

FormResponseComponent.propTypes = {
    response: PropTypes.string.isRequired,
    featuresList: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleReset: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    fbShareLink: PropTypes.string.isRequired,
    twitterShareLink: PropTypes.string.isRequired,
    linkedinShareLink: PropTypes.string.isRequired,
    whatsappShareLink: PropTypes.string.isRequired,
    isSmallScreen: PropTypes.bool.isRequired,
    eventId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
};

export default FormResponseComponent;