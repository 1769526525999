import React from "react";
import {
    Container,
    LinearProgress,
    Box,
    Paper,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet"
import { IMAGES_API_URL } from "../constant";
import { useFeatures } from "../hooks/GetFeatures";
import LinkNotFound from "../components/LinkNotFound";
import EventClosed from "../components/EventClosed";
import NotFound from "../components/NotFound";
import LimitExhausted from "../components/LimitExhausted";
import SponsorLinkError from "../components/SponsorLinkError";
import FormResponseComponent from "../components/FormResponseComponent";
import RegForm from "../components/RegForm";
import bgImagelight from "../Assets/newImage/header-background.jpg";
import bgImagedark from "../Assets/newImage/header-background-dark.jpg";
import ThemeToggleButton from "../components/ThemeToggleButton";
import LanguageSwitcher from "../components/LanguageSwitcher";

export const DECRYPT_PMT = gql`
  query DecryptPmt($encryptedPmt: String!) {
    decryptPmt(encryptedPmt: $encryptedPmt)
  }
`;

const RegistrationPage = () => {
    const { navigate } = useNavigate();
    let { eventId, role } = useParams();
    if (!eventId || !role) {
        eventId = "6220072402";
        role = "visitor";
        navigate(`/registration/${eventId}/${role}`);
    }
    const theme = useTheme();
    const { data, loading, error } = useQuery(GET_REG_STRUCTURE, {
        variables: { eventId, role },
        skip: !eventId || !role,
    });
    const searchParams = new URLSearchParams(window.location.search);
    const encryptedPmt = searchParams.get("pmt");
    const link = data?.regv2.link || "";

    const { data: pmtData, loading: pmtLoading, error: pmtError } = useQuery(DECRYPT_PMT, {
        variables: { encryptedPmt },
        skip: !encryptedPmt,
    });

    const pmt = pmtData?.decryptPmt ? JSON.parse(pmtData.decryptPmt) : null;
    const responseBoxRef = React.useRef(null);
    const { featureData, featureLoading } = useFeatures(data?.regv2?.eventId, data?.regv2?.role);
    const [response, setResponse] = React.useState("");
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const title = encodeURIComponent(data?.regv2?.eventName || "Event");
    const image = getImageUrl(link, data?.regv2?.eventId);
    const featuresList = getFeaturesList(featureData);
    const shareLinks = getShareLinks(featuresList, data?.regv2?.shareContent, title, image);

    React.useEffect(() => {
        initializeFacebookSDK();
    }, []);

    React.useEffect(() => {
        responseBoxRef?.current?.scrollIntoView();
    }, [response]);
    console.log(eventId, role)
    if (!eventId || !role) return <NotFound />;
    if (loading || featureLoading || pmtLoading) return <LinearProgress />;
    if (pmtError) return <SponsorLinkError pmtError={pmtError} />;
    if (error?.message === "This registration page has been deactivated by admin") return <EventClosed />;
    if (!data?.regv2?.eventId) return <LinkNotFound />;
    if (pmt && pmt.isExhausted) return <LimitExhausted pmt={pmt} />;

    return (
        <React.Fragment>
            <Helmet>
                <title>{data.regv2.eventName}</title>
                <meta property="og:image" content={image} />
                <meta property="twitter:image" content={image} />
                <meta property="og:title" content={data.regv2.eventName} />
                <meta
                    property="og:description"
                    content="An Event Registration System (ERS) is a digital platform designed to facilitate the process of enrolling attendees for events. It provides event organizers with tools to create event listings, manage attendee data, and handle payments. Key features often include"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${IMAGES_API_URL}favicon/${eventId}/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${IMAGES_API_URL}favicon/${eventId}/favicon-32x32.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${IMAGES_API_URL}favicon/${eventId}/favicon-16x16.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${IMAGES_API_URL}favicon/${eventId}/favicon.ico`}
                />
                <link
                    rel="manifest"
                    href={`${IMAGES_API_URL}favicon/${eventId}/site.webmanifest`}
                />
            </Helmet>
            <Box sx={{ backgroundImage: `url(${theme.palette.mode === "light" ? bgImagelight : bgImagedark})`, backgroundSize: "cover", backgroundPosition: "center" }} >
                <LanguageSwitcher position="fixed" />
                <ThemeToggleButton position="fixed" />
                <Box sx={{paddingTop:7}}>
                    {data?.regv2.eventId && (
                        <img
                            src={`${IMAGES_API_URL}banner/${data?.regv2.eventId}.jpeg`
                            }
                            width="100%"
                            alt="Banner"
                            style={{padding:0,margin:0}}
                        onError={(e) => {
                                e.target.style.display = "none";
                            }}
                        />
                    )}
                </Box>
                <Container maxWidth="lg" sx={{ pb: 3 }}>

                    {/* <Box p={3} /> */}
                    {!response && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.regv2.formHeading
                                    ? `<h2 style="text-align: center; margin-bottom: 16px;">${data.regv2.formHeading}</h2>`
                                    : `<h4 style="text-align: center; margin-bottom: 16px; text-transform: capitalize;">${data.regv2.role} Registration</h4>`,
                            }}
                        ></div>
                    )}
                    <Paper variant="outlined" sx={{ p: { xs: 1, md: 2 } }}>
                        {response ? (
                            <FormResponseComponent
                                response={response}
                                handleReset={() => setResponse("")}
                                fbShareLink={shareLinks.fbShareLink}
                                twitterShareLink={shareLinks.twitterShareLink}
                                linkedinShareLink={shareLinks.linkedinShareLink}
                                whatsappShareLink={shareLinks.whatsappShareLink}
                                isSmallScreen={isSmallScreen}
                                responseBoxRef={responseBoxRef}
                                featuresList={featuresList}
                                eventId={data.regv2.eventId || "6220072402"}
                                role={data.regv2.role || "visitor"}
                            />
                        ) : (
                            <RegForm
                                fields={data.regv2.fields}
                                onSubmit={(res) => setResponse(res)}
                                eventId={data.regv2.eventId}
                                role={data.regv2.role}
                                featuresList={featuresList}
                                pmt={pmt || null}
                                link={link}
                                isSmallScreen={isSmallScreen}
                            />
                        )}
                    </Paper>
                </Container>
            </Box>
        </React.Fragment>
    );
};

const getImageUrl = (link, eventId) => {
    return link === "test-visitor-form"
        ? `${IMAGES_API_URL}banner/cse-landing-banner.jpg`
        : `${IMAGES_API_URL}banner/${eventId}.jpeg`;
};

const getFeaturesList = (featureData) => {
    return featureData && featureData?.getFeatureList
        ? JSON.parse(featureData?.getFeatureList.features)
        : [];
};

const getShareLinks = (featuresList, shareContent, title, image) => {
    if (!featuresList.includes("ShareButton")) return {};

    const description = shareContent ? JSON.parse(shareContent) : null;
    const encodedDescription = encodeURIComponent(description);
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const foundUrls = Array.isArray(description) && description.length > 0 ? description.match(urlRegex) : "";
    const extractedUrl = foundUrls ? foundUrls[0] : null;

    return {
        twitterShareLink: `https://twitter.com/intent/tweet?text=${encodedDescription}`,
        linkedinShareLink: `https://www.linkedin.com/sharing/share-offsite/?url=${extractedUrl}`,
        whatsappShareLink: `https://api.whatsapp.com/send?text=${title} - ${encodedDescription}`,
        fbShareLink: `https://www.facebook.com/sharer/sharer.php?u=${extractedUrl}&quote=${encodedDescription}&picture=${image}`,
    };
};

const initializeFacebookSDK = () => {
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: "273754185393849",
            autoLogAppEvents: true,
            xfbml: true,
            version: "v11.0",
        });
    };
};



export default RegistrationPage;

const GET_REG_STRUCTURE = gql`
  query ($eventId: String!, $role: String!) {
    regv2(eventId: $eventId, role: $role) {
      link
      eventId
      eventName
      formHeading
      shareContent
      role
      fields {
        name
        type
        label
        sx
        xs
        md
        placeholder
        options
        defaultValue
        minLength
        maxLength
        pattern
        required
        readonly
      }
    }
  }
`;
