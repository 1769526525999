import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const DownloadBadgeComponent = ({ handleModalOpen }) => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
            p={2}
        >
            <Typography variant="body1" textAlign="center">
                إذا قمت بالتسجيل مسبقًا،{" "}
                <Typography
                    variant="caption"
                    onClick={handleModalOpen}
                    sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}
                >
                    اضغط هنا{" "}
                </Typography>
                لاسترجاع بطاقة الدخول.
                <br />
                If you have previously registered,{" "}
                <Typography
                    variant="caption"
                    onClick={handleModalOpen}
                    sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}
                >
                    click here{" "}
                </Typography>
                to retrieve your badge.
            </Typography>
        </Stack>
    );
};

DownloadBadgeComponent.propTypes = {
    handleModalOpen: PropTypes.func.isRequired,
};

export default DownloadBadgeComponent;