import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import GppBadIcon from '@mui/icons-material/GppBad';

const PaymentFailedComponent = ({ message }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
            >
                <GppBadIcon sx={{ fontSize: 96, color: "error" }} color={"error"} />
                <Typography
                    variant="h5"
                    color={"black"}
                    sx={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                    {message}
                </Typography>
                <CircularProgress color="error" size={36} />
            </Stack>
        </Box>
    );
};

PaymentFailedComponent.propTypes = {
    message: PropTypes.string.isRequired,
};

export default PaymentFailedComponent;