import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import Login from './pages/Login';
import Homepage from './pages/Homepage';
import RegistrationPage from './pages/RegistrationPage';
import NotFound from './components/NotFound';
import Layout from './layout/Layout';
import MyBadge from './pages/MyBadge';
import FloorPlan from './pages/FloorPlan';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/login/:eventId/:role" element={<Login />} />
      <Route path="/register/:eventId/:role" element={<RegistrationPage />} />
      <Route element={<Layout />}>
        <Route path="/" element={<AuthRoute><Homepage /></AuthRoute>} />
        <Route path="/my-badge" element={<AuthRoute><MyBadge /></AuthRoute>} />
        <Route path="/floor-plan" element={<AuthRoute><FloorPlan /></AuthRoute>} />
        <Route path="/page-one" element={<AuthRoute><Homepage /></AuthRoute>} />
      </Route>
      <Route path="*" element={<AuthRoute><NotFound /></AuthRoute>} />
    </Routes>
  );
}

export default App;