import { setMcqValidation } from "./setMcqValidation";

export const setTypeValidation = (v, f) => {
    switch (f.type) {
        case "checkbox":
            return v.boolean();
        case "select":
            return v.string();
        case "mcq":
            return setMcqValidation(v, f);
        case "radio":
        case "payment":
            return v.string();
        case "number":
            return v.number();
        case "email":
            return v.string().trim().email();
        case "confirm_email":
            return v.string().trim().test({
                message: "Does not match",
                test: (val, p) => {
                    if ("Email Address" in p.parent) {
                        return val === p.parent["Email Address"];
                    }
                    return val === p.parent["Email"];
                },
            });
        default:
            return v.string().trim();
    }
};