import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import MapIcon from '@mui/icons-material/Map';
import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventIcon from '@mui/icons-material/Event';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StarIcon from '@mui/icons-material/Star';
import useUserData from '../hooks/useUserData';
import MenuCard from '../components/MenuCard';
import { useTranslation } from 'react-i18next';

const Homepage = () => {
  const { data } = useUserData();
  const { t,i18n } = useTranslation();
  let me = data?.me;
  if (typeof (data?.me) === "string") (me = JSON.parse(data?.me));

  const menuItems = [
    { label: t('myBadge'), color: 'linear-gradient(45deg, #1e3c72, #2a5298)', icon: <BadgeIcon sx={{ fontSize:  72 }} />, rowspan: 2, colspan: 2, link: '/my-badge' },
    { label: t('floorPlan'), color: 'linear-gradient(45deg, #ff512f, #dd2476)', icon: <MapIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/floor-plan' },
    { label: t('attendees'), color: 'linear-gradient(45deg, #24c6dc, #514a9d)', icon: <PeopleIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/attendees' },
    { label: t('exhibitors'), color: 'linear-gradient(45deg, #ff5f6d, #ffc371)', icon: <StoreIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/exhibitors' },
    { label: t('products'), color: 'linear-gradient(45deg, #36d1dc, #5b86e5)', icon: <ShoppingCartIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/products' },
    { label: t('conferences'), color: 'linear-gradient(45deg, #ff4e50, #f9d423)', icon: <EventIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/conferences' },
    { label: t('networking'), color: 'linear-gradient(45deg, #00c6ff, #0072ff)', icon: <NetworkWifiIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/networking' },
    { label: t('myAgenda'), color: 'linear-gradient(45deg, #f7971e, #ffd200)', icon: <ScheduleIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/my-agenda' },
    { label: t('sponsors'), color: 'linear-gradient(45deg, #00b09b, #96c93d)', icon: <StarIcon sx={{ fontSize:  72 }} />, rowspan: 1, colspan: 1, link: '/sponsors' },
  ];
  console.log("Homepage",i18n.language)
  const eventName = i18n.language === 'ar' ? t(me?.eventName) : me?.eventName;
  return (
    <Box
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          width: '100%',
          p: 2,
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Typography variant="h2" fontWeight="bold">
          {eventName}
        </Typography>
        {/* <Typography variant="subtitle1">Expand North Star</Typography> */}
      </Box>

      {/* Grid Menu */}
      <Box sx={{ flexGrow: 1, p: 3, width: '100%' }}>
        <Grid container spacing={2} justifyContent="center">
          {menuItems.map((item, index) => (
            <Grid
              item
              xs={item.colspan * 6}
              sm={item.colspan * 4}
              md={item.colspan * 3}
              key={`${item.label}-${index}`}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gridRow: `span ${item.rowspan}`,
              }}
            >
              <MenuCard
                color={item.color}
                label={item.label}
                icon={item.icon}
                rowspan={item.rowspan}
                link={item.link}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Homepage;