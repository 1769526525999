import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { Grid, Divider, Typography, FormControl, FormLabel, RadioGroup, Stack, FormControlLabel, Checkbox, LinearProgress, Link, Box, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import MyField from './MyField';
import SearchModal from './SearchModal';
import OtpModal from './OtpModal';
import PaymentSummaryDialog from './PaymentSummaryDialog';
import PaymentFailedComponent from './PaymentFailedComponent';
import PaymentProcessComponent from './PaymentProcessComponent';
import CustomRadioButton from './CustomRadioButton';
import DownloadBadgeComponent from './DownloadBadgeComponent';
import countries from '../countries.json';
import useCheckEmail from '../hooks/useCheckEmail';
import { buildValidation } from '../validation/buildValidation';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { usePaymentDetail } from '../hooks/GetPaymentDetail';
import useGeoLocation from 'react-ipgeolocation';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from "../Assets/eventegration.png";

const RegForm = ({
    fields,
    onSubmit,
    eventId,
    role,
    featuresList,
    registrationId,
    pmt,
    isSmallScreen,
    link
}) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [clientId, setClientId] = React.useState(null);
    const [price, setPrice] = React.useState(null);
    const [selectedCurrency, setSelectedCurrency] = React.useState(null);
    const [allData, setAllData] = React.useState(null);
    const [apiurl, setApiurl] = React.useState(null);
    const [secretKey, setSecretKey] = React.useState(null);
    const [publicKey, setPublicKey] = React.useState(null);
    const [emailError, setEmailError] = React.useState("");
    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState("");
    const [paymentFailedLoading, setPaymentFailedLoading] = React.useState(false);
    const [token, setToken] = React.useState(null);
    const [captchaOff, setCaptchaOff] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [selectedMobileCode, setSelectedMobileCode] = React.useState(null);

    const theme = useTheme();

    const [isDefaultCountrySelected, setIsDefaultCountrySelected] =
        React.useState(false);
    const [errorInPaymentInitialization, setErrorInPaymentInitialization] =
        React.useState(false);
    const [isPopupNotOpened, setIsPopupNotOpened] = React.useState(false);
    const parsedRegistration = registrationId && typeof (registrationId) === "string" ? JSON.parse(registrationId) : registrationId;

    const [showFamilyMembers, setShowFamilyMembers] = React.useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const { checkEmail: isEmailTaken, loading: emailCheckLoading } =
        useCheckEmail(
            !featuresList?.includes("duplicateEmailAllowed") ? email : null,
            !featuresList?.includes("duplicateEmailAllowed") ? link : null
        );

    const [otpModalProps, setOtpModalProps] = React.useState({
        isOpen: false,
        mobile: null,
        eventId: null,
        onVerify: null,
    });
    const turnstile = useTurnstile();
    const { paymentData } = usePaymentDetail(eventId, role);

    React.useEffect(() => {
        if (link && link.includes("survey")) {
            navigate(`/survey/${link}`);
        }
    }, [link, navigate]);

    const {
        country: geoCountry,
        error: geoError,
        isLoading: geoIsLoading,
    } = useGeoLocation();
    console.log(geoError, geoIsLoading);
    React.useEffect(() => {
        if (
            !isDefaultCountrySelected &&
            geoCountry &&
            featuresList?.includes("GeoLocation")
        ) {
            const isdCode = countries.find((c) => c.code === geoCountry);
            setSelectedCountry(isdCode?.label);
            setIsDefaultCountrySelected(true);
            setSelectedMobileCode(isdCode?.dial_code);
        }
    }, [geoCountry, isDefaultCountrySelected]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [
        doPaymentRegister,
        { loading: PaymentRegisterLoading, error: PaymentRegisterError },
    ] = useMutation(PAYMENT_REGISTER);


    const handlePaymentSuccess = async (details, actions) => {
        try {
            if (
                details?.payment_status === "COMPLETED" ||
                details?.payment_status === "success"
            ) {
                handleClose();
                const updatedAllData = { ...allData, payment_status: "success" };
                const reglink = pmt ? pmt.param : null;
                const mobile = allData?.mobile || allData?.phone || allData?.Mobile;
                const calling_code = allData?.calling_code || allData?.country_code || allData?.CallingCode;
                const updatedMobile = mobile ? `${calling_code.replace('+', '')}${mobile}` : null;
                const isOTPRequired = featuresList?.includes("OTP");

                if (isOTPRequired) {
                    // Show OTP Modal and wait for verification
                    const otpVerified = await new Promise((resolve) => {
                        setOtpModalProps({
                            isOpen: true,
                            mobile: updatedMobile,
                            eventId: eventId, // Pass event ID if available
                            onVerify: (success) => {
                                setOtpModalProps({ ...otpModalProps, isOpen: false });
                                resolve(success);
                            },
                        });
                    });

                    if (!otpVerified) {
                        console.log("OTP verification failed.");
                        return; // Stop further execution if OTP verification fails
                    }
                }
                const result = await doPaymentRegister({
                    variables: {
                        link: JSON.stringify({ link, pmtLink: reglink }),
                        jsonData: JSON.stringify(updatedAllData),
                        transaction: JSON.stringify(details),
                    },
                }).then((res) => res.data?.paymentRegisterv2);

                if (featuresList.includes("Captcha") && result) {
                    turnstile.reset();
                    setToken(null);
                    setCaptchaOff(true);
                }
                setSelectedPaymentOption("");

                return onSubmit(result);
            }
            if (
                details?.payment_status === "FAILED" ||
                details?.payment_status === "failed"
            ) {
                handleClose();
                setPaymentFailedLoading(true);
            }
        } catch (error) {
            setEmailError(
                `Payment Status: ${details?.payment_status} <br /> Registration Status: ${error?.message} <br/> Please contact organizer to resolve this issue. Sorry for the in convinience`
            );
            console.error("Error during payment:", error);
        }
    };

    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    };
    const getDefaultValue = (r) => {
        if (r.type === "checkbox") {
            return !!parseInt(r.defaultValue || "0");
        } else if (r.type === "mcq") {
            return [];
        } else {
            return r.defaultValue || "";
        }
    };

    const { control, handleSubmit, setValue, watch, reset } = useForm({
        defaultValues: fields.reduce(
            (a, r) => ({
                ...a,
                [r.name]: getDefaultValue(r),
            }),
            {}
        ),
        resolver: yupResolver(
            yup.object().shape({
                ...fields.reduce(
                    (a, r) => ({ ...a, [r.name]: buildValidation(r) }),
                    {}
                ),
                ...fields
                    .filter((r) => r.options?.some((op) => op === "$Other"))
                    ?.reduce(
                        (a, r) => ({
                            ...a,
                            [r.name + "_other"]: yup
                                .string()
                                .min(r.minLength, "Too long")
                                .max(r.maxLength, "Too long")
                                // .matches(r.pattern, "Invalid pattern")
                                .test({
                                    message: "Required",
                                    test: (a, p) =>
                                        p.parent[r.name]?.includes("$Other") ? !!a : true,
                                }),
                        }),
                        {}
                    ),
            })
        ),
    });
    const handleClose = () => {
        setOpen(false);
        (errorInPaymentInitialization || isPopupNotOpened) && reset();
        errorInPaymentInitialization && setErrorInPaymentInitialization(false);
        isPopupNotOpened && setIsPopupNotOpened(false);
        errorInPaymentInitialization && setErrorInPaymentInitialization(false);
        isPopupNotOpened && setIsPopupNotOpened(false);
    };

    React.useEffect(() => {
        if (errorInPaymentInitialization) {
            handleClose();
            setEmailError("Something went wrong please Try Again Later!");
        }
        if (isPopupNotOpened) {
            handleClose();
            setEmailError(
                "Please allow pop-ups for this website to proceed with the payment. \n In the address bar, click the Pop-up blocked icon 🔒. then select always allow popups"
            );
        }

    }, [errorInPaymentInitialization, isPopupNotOpened]);

    const [doRegister, { loading: registerLoading, error: registerError }] =
        useMutation(REGISTER);
    const findCountryCodeByDialCode = (dialCode) => {
        const country = countries.find((c) => c.dial_code === dialCode);
        return !selectedCountry ? country.label : "Oman";
    };
    const handleFormSubmission = useCallback(async (f) => {
        if (isEmailTaken === "Email already exists" && !featuresList.includes("duplicateEmailAllowed")) {
            return;
        }

        const v = processFields(f, fields);

        if (featuresList.includes("Captcha") && !captchaOff) {
            if (!token) {
                setEmailError("Captcha Required");
                return;
            }
            setEmailError(null);
        } else {
            setEmailError(null);
        }

        const isPmtTrue = pmt === null ? true : pmt?.amount > 0;
        try {
            if (featuresList?.includes("PaymentEnable") && isPmtTrue) {
                await handlePayment(f, v);
            } else {
                await handleRegistration(v);
            }
        } catch (e) {
            console.log(e);
        }
    });

    const processFields = useCallback((f, fields) => {
        const v = {};
        for (const r of fields) {
            if (r.options?.some((op) => op === "$Other")) {
                processOtherField(f, r, v);
            } else if (typeof f[r.name] === "object") {
                v[r.name] = f[r.name].join(", ");
            } else {
                v[r.name] = f[r.name];
            }
        }
        return v;
    }, []);

    const processOtherField = useCallback((f, r, v) => {
        if (["select", "radio", "payment"].includes(r.type)) {
            if (f[r.name] === "$Other") {
                v[r.name] = "Other: " + (f[r.name + "_other"] || "");
            }
        } else if (typeof f[r.name] === "object") {
            if (f[r.name]?.includes("$Other")) {
                v[r.name] = [
                    ...(f[r.name]?.filter((o) => o !== "$Other") || []),
                    "Other: " + (f[r.name + "_other"] || ""),
                ]
                    .filter((a) => !!a?.trim())
                    .join(", ");
            } else {
                v[r.name] = (f[r.name] || [])
                    .filter((a) => !!a?.trim())
                    .join(", ");
            }
        }
    }, []);

    const handlePayment = async (f, v) => {
        let wholeData = {};
        if (paymentData?.length > 1) {
            wholeData = { paymentMethod: f.paymentMethod, ...v };
            await processPayment(wholeData);
        } else if (paymentData?.length === 1) {
            wholeData = { paymentMethod: paymentData[0]?.paymentMethod, ...v };
            await processPayment(wholeData);
        }
    };

    const processPayment = async (wholeData) => {
        try {
            const match = wholeData.payment.match(/\d+/);
            const amount = match ? parseFloat(match[0]) : 0;
            const matchingPaymentMethodData = paymentData.find(
                (data) => data.paymentMethod === wholeData.paymentMethod
            ) || paymentData[0];

            const { apiKey, apiurl, secretKey, publicKey, currency } = matchingPaymentMethodData || {};
            setAllData(wholeData);
            setPrice(amount);
            setSelectedCurrency(currency);
            setClientId(apiKey);
            setApiurl(apiurl);
            setSecretKey(secretKey);
            setPublicKey(publicKey);
            handleClickOpen();
        } catch (error) {
            console.error("Error during payment:", error);
        }
    };

    const handleRegistration = async (v) => {
        const reglink = pmt ? pmt.param : null;
        const mobile = v?.mobile || v?.phone || v?.Mobile;
        const calling_code = v?.calling_code || v?.country_code || v?.CallingCode;
        const updatedMobile = mobile ? `${calling_code.replace('+', '')}${mobile}` : null;
        const isOTPRequired = featuresList?.includes("OTP");

        if (isOTPRequired) {
            const otpVerified = await new Promise((resolve) => {
                setOtpModalProps({
                    isOpen: true,
                    mobile: updatedMobile,
                    eventId: eventId,
                    onVerify: (success) => {
                        setOtpModalProps({ ...otpModalProps, isOpen: false });
                        resolve(success);
                    },
                });
            });

            if (!otpVerified) {
                console.log("OTP verification failed.");
                return;
            }
        }

        const result = await doRegister({
            variables: {
                link: JSON.stringify({ link, pmtLink: reglink }),
                jsonData: JSON.stringify(v),
            },
        })
            .then((res) => res.data?.registerv2)
            .catch((e) => false);

        if (featuresList.includes("Captcha") && result) {
            turnstile.reset();
            setToken(null);
            setCaptchaOff(true);
        }
        console.log(result)
        return onSubmit(result);
    };

    React.useEffect(() => {
        let timer;
        if (paymentFailedLoading) {
            timer = setTimeout(() => {
                setPaymentFailedLoading(false);
                window.location.reload();
            }, 7000); // 7 seconds
        }

        return () => clearTimeout(timer);
    }, [paymentFailedLoading]);

    if (paymentFailedLoading) {
        return <PaymentFailedComponent message={"Payment failed. Please try again."} />;
    }
    if (PaymentRegisterError) {
        console.log(PaymentRegisterError);
        return <PaymentFailedComponent message={"Something went wrong. Please try again."} />;
    }

    if (PaymentRegisterLoading)
        return (
            <PaymentProcessComponent message={"Processing payment. Please wait..."} />
        );

    return (
        <>
            <SearchModal
                eventId={eventId}
                role={role}
                open={openModal}
                onClose={handleModalClose}
            />
            <OtpModal  {...otpModalProps} />
            {featuresList.includes("DownloadBadge") && (
                <DownloadBadgeComponent handleModalOpen={handleModalOpen} />
            )}

            <form
                noValidate
                onSubmit={handleSubmit(handleFormSubmission)}
            >
                <Grid container spacing={2}>
                    {fields
                        ?.map((f) => ({
                            ...f,
                            sx: f.sx ? JSON.parse(f.sx) : {},
                        }))
                        ?.map((f) => (
                            <React.Fragment key={f.name}>
                                <Grid
                                    item
                                    xs={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.xs}
                                    md={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.md}
                                >
                                    <Controller
                                        name={f.name}
                                        control={control}
                                        render={(rhf) => (
                                            <MyField
                                                rhf={rhf}
                                                f={f}
                                                setValue={setValue}
                                                findCountryCodeByDialCode={findCountryCodeByDialCode}
                                                selectedCountry={selectedCountry}
                                                setSelectedCountry={setSelectedCountry}
                                                selectedMobileCode={selectedMobileCode}
                                                setSelectedMobileCode={setSelectedMobileCode}
                                                parsedRegistration={parsedRegistration}
                                                pmt={pmt}
                                                setEmail={setEmail}
                                                isEmailTaken={isEmailTaken}
                                                emailCheckLoading={emailCheckLoading}
                                                showFamilyMembers={showFamilyMembers}
                                            />
                                        )}
                                    />
                                </Grid>
                                {f.options?.some((op) => op === "$Other") &&
                                    watch(f.name)?.includes("$Other") && (
                                        <Grid item xs={f.xs} md={f.md}>
                                            <Controller
                                                name={f.name + "_other"}
                                                control={control}
                                                rules={{
                                                    required: f.required ? "Required" : false,
                                                    maxLength: f.maxLength,
                                                    minLength: f.minLength,
                                                    // pattern: f.pattern
                                                }}
                                                render={(rhf) => (
                                                    <MyField
                                                        rhf={rhf}
                                                        parsedRegistration={parsedRegistration}
                                                        f={{
                                                            type: "text",
                                                            name: "Enter value",
                                                            placeholder: "Custom value",
                                                            required: f.required,
                                                            sx: {},
                                                        }}
                                                        selectedCountry={selectedCountry}
                                                        setSelectedCountry={setSelectedCountry}
                                                        setValue={setValue}
                                                        findCountryCodeByDialCode={
                                                            findCountryCodeByDialCode
                                                        }
                                                        selectedMobileCode={selectedMobileCode}
                                                        setSelectedMobileCode={setSelectedMobileCode}
                                                        pmt={pmt}
                                                        setEmail={setEmail}
                                                        isEmailTaken={isEmailTaken}
                                                        emailCheckLoading={emailCheckLoading}
                                                        showFamilyMembers={showFamilyMembers}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                            </React.Fragment>
                        ))}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {registerError && (
                        <Grid item xs={12}>
                            <Typography color="error">{registerError?.message}</Typography>
                        </Grid>
                    )}
                    {registerLoading && (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    )}
                    {pmt && pmt.amount === 0
                        ? null
                        : paymentData?.length > 1 &&
                        featuresList.includes("PaymentEnable") && (
                            <Grid item xs={12}>
                                <Controller
                                    name="paymentMethod"
                                    control={control}
                                    defaultValue={paymentData[0]?.paymentMethod}
                                    rules={{ required: "Please select a payment method" }}
                                    render={({ field }) => (
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Select Payment Option
                                            </FormLabel>
                                            <RadioGroup {...field}>
                                                <Stack direction="row" spacing={2}>
                                                    {paymentData.map((item, i) => (
                                                        <CustomRadioButton
                                                            key={`${item}-${i}`}
                                                            item={item}
                                                            setSelectedPaymentOption={
                                                                setSelectedPaymentOption
                                                            }
                                                        />
                                                    ))}
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                        )}

                    <Grid item xs={12}>
                        <Stack
                            direction="column"
                            justifyContent={"center"}
                            spacing={2}
                            alignItems="center"
                        >
                            {featuresList.includes("GroupBookingEnable") && ( // Check if GroupBookingEnable feature is enabled
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showFamilyMembers}
                                            onChange={(e) => setShowFamilyMembers(e.target.checked)} // Update state on checkbox change
                                        />
                                    }
                                    label="Add Family Members"
                                />
                            )}
                            {featuresList.includes("Captcha") && !captchaOff && (
                                <Turnstile
                                    // sitekey="1x00000000000000000000AA"
                                    sitekey="0x4AAAAAAAXSYmh0wYoO56yA"
                                    onVerify={(token) => {
                                        setToken(token);
                                    }}
                                />
                            )}
                            {emailError && (
                                <Typography color="error">
                                    <div dangerouslySetInnerHTML={{ __html: emailError }}></div>
                                </Typography>
                            )}
                            <LoadingButton
                                loading={registerLoading}
                                type="submit"
                                size="large"
                                variant="contained"
                                color='secondary'
                                sx={{
                                    width: { xs: "100%", md: 220 },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 1, // Adds space between the text and the icon
                                    flexDirection: "row-reverse", // Arabic text first, English and icon follow
                                }}
                            >
                                <Typography component="span" color={"inherit"} sx={{ direction: "rtl" }}>
                                    إرسال
                                </Typography>
                                <AppRegistrationIcon />
                                <Typography component="span" color={"inherit"} sx={{ direction: "ltr" }}>
                                    Submit
                                </Typography>
                            </LoadingButton>
                            <Typography align="center" sx={{ mt: 2 }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        navigate(`/login/${eventId || "6220072402"}/${role || "visitor"}`);
                                    }}
                                >
                                    انقر هنا لتسجيل الدخول
                                </Link>
                                {" "} مسجل بالفعل؟
                            </Typography>
                            <Typography align="center" sx={{ mb: 2 }}>
                                Already registered?{" "}
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        navigate(`/login/${eventId || "6220072402"}/${role || "visitor"}`);
                                    }}
                                >
                                    Click here to log in
                                </Link>
                            </Typography>
                            <Stack px={4} mt={6} direction={"column"} justifyContent={"end"}>
                                <Typography align="center" color={theme.palette.text.secondary} fontSize={10} gutterBottom>
                                    Powered by...
                                </Typography>
                                <Stack direction={"row"} justifyContent={"center"}>
                                    <Box
                                        width={isSmallScreen ? "50%" : "20%"}
                                        sx={{ backgroundColor: "transparent", borderRadius: 1, cursor: "pointer" }}
                                        onClick={() => {
                                            window.location.href = "https://eventegration.com";
                                        }}
                                    >
                                        <img src={logo} width="95%" alt="Logo" />
                                    </Box>
                                </Stack>
                            </Stack>

                        </Stack>

                        <PaymentSummaryDialog
                            open={open}
                            handleClose={handleClose}
                            price={price}
                            selectedCurrency={selectedCurrency}
                            clientId={clientId}
                            secretKey={secretKey}
                            publicKey={publicKey}
                            handlePaymentSuccess={handlePaymentSuccess}
                            allData={allData}
                            setErrorInPaymentInitialization={setErrorInPaymentInitialization}
                            selectedPaymentOption={selectedPaymentOption}
                            base={apiurl}
                            setIsPopupNotOpened={setIsPopupNotOpened}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
export default React.memo(RegForm);
RegForm.propTypes = {
    fields: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    featuresList: PropTypes.array.isRequired,
    registrationId: PropTypes.string,
    link: PropTypes.string,
    pmt: PropTypes.object,
    isSmallScreen: PropTypes.bool,
};

const REGISTER = gql`
  mutation ($link: String!, $jsonData: String!) {
    registerv2(link: $link, jsonData: $jsonData){
    response
    email
    password
    }
  }
`;

const PAYMENT_REGISTER = gql`
  mutation ($link: String!, $jsonData: String!, $transaction: String!) {
    paymentRegisterv2(link: $link, jsonData: $jsonData, transaction: $transaction){
    response
    email
    password
    }
  }
`;