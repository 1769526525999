import { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Typography, Stack } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";

const PaymobBtns = ({
  amount,
  onPaymentSuccess,
  currency,
  description,
  buyerEmail,
  secretKey,
  publicKey,
  allData,
  base,
  setErrorInPaymentInitialization,
  setIsPopupNotOpened,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes

  const intervalIdRef = useRef(null);
  const paymentWindowRef = useRef(null);
  const clientSecretRef = useRef(null);
  const intentionIdRef = useRef(null);

  const firstName = allData?.["Full Name"] || allData?.["First Name"] || "Nil";
  const lastName = allData?.["Last Name"] || "Nil";
  const mobile =
    allData?.["Mobile"] || allData?.["Phone"] || allData?.["mobile"] || "Nil";
  const country = allData?.["Country"] || "Nil";

  useEffect(() => {
    const initializePayment = async () => {
      try {
        setLoading(true);
        const paymentData = await createIntention();
        clientSecretRef.current = paymentData.client_secret;
        intentionIdRef.current = paymentData.id;

        const paymentUrl = `https://oman.paymob.com/unifiedcheckout/?publicKey=${publicKey}&clientSecret=${clientSecretRef.current}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
          setIsPopupNotOpened(true);
          throw new Error(
            "Popup blocked! Please allow pop-ups for this site to proceed with payment."
          );
        }

        paymentWindowRef.current = newWindow;

        intervalIdRef.current = setInterval(checkPaymentStatus, 7000);
      } catch (error) {
        handleError("Payment initialization failed. Please try again.", error);
        setErrorInPaymentInitialization(true);
      } finally {
        setLoading(false);
      }
    };

    initializePayment();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !paymentSuccess) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }

    if (timeLeft === 0) {
      finalizePayment("failed", "Payment timeout. Please try again.");
    }
  }, [timeLeft, paymentSuccess]);

  const createIntention = async () => {
    try {
      const url = "https://oman.paymob.com/v1/intention/";
      const headers = { authorization: `Token ${secretKey}` };

      const amountInMinorUnits = currency === "OMR" ? amount * 1000 : amount;
      const billingData = {
        apartment: "Nil",
        email: buyerEmail,
        floor: "Nil",
        first_name: firstName,
        street: "Nil",
        building: "Nil",
        phone_number: mobile,
        shipping_method: "Virtual",
        postal_code: "Nil",
        city: "Nil",
        country,
        last_name: lastName,
        state: "Nil",
      };

      const payload = {
        amount: amountInMinorUnits,
        currency,
        payment_methods: JSON.parse(base),
        items: [
          {
            name: description,
            amount: amountInMinorUnits,
            description,
            quantity: 1,
          },
        ],
        billing_data: billingData,
        customer: {
          first_name: firstName,
          last_name: lastName,
          email: buyerEmail,
        },
        integrations: base,
      };

      const response = await axios.post(url, payload, { headers });
      return response.data;
    } catch (error) {
      throw new Error("Failed to create payment intention.");
    }
  };

  const checkPaymentStatus = async () => {
    try {
      if (!intentionIdRef.current) return;

      const response = await axios.get(
        `https://api-admin.eventegration.com/graphql/checkpaymentId/${intentionIdRef.current}`
      );
      const status = response?.data?.status;

      if (status === "success") {
        finalizePayment("success");
      } else if (status === "failed") {
        finalizePayment("failed", "Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  const finalizePayment = (status, message = "") => {
    clearInterval(intervalIdRef.current);

    const details = {
      product_name: description,
      amount,
      payment_id: intentionIdRef.current,
      paymentMethod: "Paymob",
      payment_status: status,
      email: buyerEmail,
      currency,
      invoice: intentionIdRef.current,
    };

    const actions = { payment_status: status.toUpperCase() };
    onPaymentSuccess(details, actions);

    if (status === "success") {
      setPaymentSuccess(true);
    } else {
      setErrorMessage(message || "Payment failed.");
    }

    if (paymentWindowRef.current) {
      paymentWindowRef.current.close();
    }
  };

  const handleError = (userMessage, error) => {
    console.error(error);
    setErrorMessage(userMessage);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
      }}
    >
      {loading && !errorMessage && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress color="success" size={36} />
          <Typography sx={{ marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>
            Processing your payment...
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#666" }}>
            Time left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toString().padStart(2, "0")}
          </Typography>
        </Stack>
      )}
      {errorMessage && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
      {paymentSuccess && (
        <Typography variant="h6" color="primary">
          Payment Successful!
        </Typography>
      )}
    </Box>
  );
};

PaymobBtns.propTypes = {
  amount: PropTypes.number.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buyerEmail: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
  allData: PropTypes.object.isRequired,
  base: PropTypes.string.isRequired,
  setErrorInPaymentInitialization: PropTypes.func.isRequired,
  setIsPopupNotOpened: PropTypes.func.isRequired,
};

export default PaymobBtns;
