import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Button, Box, Breadcrumbs, Typography, Link, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

const BackWithBreadcrumbAppbar = ({ links, currentPage }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <AppBar position="relative">
                <Toolbar variant="dense">
                    <Stack direction="row" spacing={2} width={"100%"} py={1} justifyContent="space-between" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<ArrowBack />}
                            color="inherit"
                            onClick={() => navigate(-1)}
                        >
                            {t('Back')}
                        </Button>
                        <Box width={30} />
                        <Breadcrumbs aria-label="breadcrumb">
                            {links.map((link, index) => (
                                <Link key={`${link}-${index}`} color={theme.palette.text.white} href={link.href}>
                                    {t(link.label)}
                                </Link>
                            ))}
                            <Typography color={theme.palette.text.white}>{t(currentPage)}</Typography>
                        </Breadcrumbs>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box height={35} />
        </>
    );
};

BackWithBreadcrumbAppbar.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    currentPage: PropTypes.string.isRequired,
};

export default BackWithBreadcrumbAppbar; 