import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          404 - Not Found
        </Typography>
      </Grid>
      <Grid item sx={{ textAlign: "center" }}>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Oops! The page you're looking for does not exist.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}
