import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MenuCard = ({ color, label, icon, rowspan, link }) => {
  const navigate = useNavigate();
console.log("MenuCard",color, label, icon, rowspan, link)
  return (
    <Card
      sx={{
        background: color, // Apply the gradient background from props
        color: 'white',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        backdropFilter: 'blur(10px)', // Glassy effect
        border: `1px solid ${color.split(',')[1]}`, // Extracting the second color for border
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 8px 16px rgba(255, 255, 255, 0.4)',
        },
      }}
      onClick={() => navigate(link)}
    >
      <CardContent>
        <IconButton sx={{ color: 'white' }}>
          {icon}
        </IconButton>
        <Typography variant="h6" sx={{ color: 'white' }}>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
};
MenuCard.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  rowspan: PropTypes.number,
  link: PropTypes.string.isRequired,
};

export default MenuCard;