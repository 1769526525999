import React, { useState } from "react";
import PropTypes from "prop-types";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CircularProgress, Stack, Typography } from "@mui/material";

const PaypalBtns = ({ amount, onPaymentSuccess, currency, buyerEmail, clientId, description,base }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            currency_code: currency,
            value: amount,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
      payer: {
        email_address: buyerEmail,
      },
    });
  };

  const onApprove = (data, actions) => {
    setLoading(true);
    actions.order.capture().then((details) => {
      onPaymentSuccess(details, actions);
      setLoading(false);
    });
  };
  

  const onError = (data, actions) => {
    console.log(data, actions,"Error");
    setErrorMessage("An error occurred with your payment. Please try again.");
    // Additional error handling can be added here
  };

  return (
    <Stack direction={"column"} spacing={2} py={2} justifyContent={"center"}  alignItems="center" sx={{width:"100%",backgroundColor:"wheat"}}>
      {loading && <CircularProgress />}
      <PayPalScriptProvider
        options={{
          "client-id": clientId,
          currency:currency,

        }}
      >
        <PayPalButtons
          style={{ layout: "vertical" }}
          amount={amount}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        /> 
      </PayPalScriptProvider>
      {errorMessage && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
      </Stack>
  );
};

export default PaypalBtns;


PaypalBtns.propTypes = {
  amount: PropTypes.number.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  buyerEmail: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  base: PropTypes.string.isRequired,

};
