import * as yup from "yup";

export const setMcqValidation = (v, f) => {
    if (f.required) {
        return v.mixed().test({
            message: "Required",
            test: (v) => {
                return v?.length > 0;
            },
        });
    } else {
        return v.array().of(yup.string());
    }
};