import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#bb86fc',
        },
        secondary: {
            main: '#03dac6',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
            white: '#fff',
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
            color: '#bb86fc',
        },
        body1: {
            fontSize: '1rem',
            color: '#ffffff',
        },
        body2: {
            fontSize: '0.875rem',
            color: '#b0bec5',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e',
                    color: '#ffffff',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    backgroundColor: '#03dac6',
                    color: '#121212',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                        backgroundColor: '#018786',
                        transform: 'scale(1.05)',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: '#1e1e1e',
                    color: '#ffffff',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1e1e1e',
                    color: '#ffffff',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#03dac6',
                    color: '#121212',
                },
            },
        },
    },
});

export default darkTheme;