import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

const CHECK_EMAIL = gql`
  query ($email: String!, $link: String!) {
    checkEmail(email: $email, link: $link)
  }
`;

const useCheckEmail = (email, link) => {
  const [checkEmail, setCheckEmail] = useState('');
  const [debouncedEmail, setDebouncedEmail] = useState(email);
  
  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedEmail(email);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [email]);

  // Use GraphQL query to check email
  const { data, loading, error } = useQuery(CHECK_EMAIL, {
    variables: { email: debouncedEmail, link },
    skip: !debouncedEmail || !link, // Skip query if email or link is empty
    fetchPolicy: 'no-cache' // Prevent caching
  });

  useEffect(() => {
    if (loading) {
      setCheckEmail('Checking email...');
    } else if (error) {
      console.error(error);
      setCheckEmail('Error checking email.');
    } else if (data?.checkEmail) {
      setCheckEmail(data?.checkEmail);
    } else {
      setCheckEmail(''); // Clear error if email is valid
    }
  }, [loading, error, data]);

  return { checkEmail, loading };
};

export default useCheckEmail;
