import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../auth';

const AuthRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};
AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthRoute;