import React from "react";
import { LoadingButton } from "@mui/lab";
import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    TextField,
    Typography,
    useTheme,
    Link,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGES_API_URL } from "../constant";
import PropTypes from "prop-types";
import sidebardImage from "../Assets/newImage/header-image.png";
import bgImagelight from "../Assets/newImage/header-background.jpg";
import bgImagedark from "../Assets/newImage/header-background-dark.jpg";
import ThemeToggleButton from "../components/ThemeToggleButton";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";

export default function LoginPage() {
    const theme = useTheme();
    const [login, { loading, error }] = useMutation(gql`
        mutation ($eventId: String!, $role: String!, $email: String!, $password: String!) {
            token: loginv2(eventId: $eventId, role: $role, email: $email, password: $password)
        }
    `);
    const { t } = useTranslation();

    const navigate = useNavigate();
    let { eventId, role } = useParams();

    const onLogin = async (variables) => {
        return login({ variables }).then((resp) => {
            console.log(resp);
            localStorage.setItem("auth.app.eventegration.com", resp.data.token);
            navigate("/");
        });
    };
    if (!eventId || !role) {
        console.log("No eventId or role", eventId);
        eventId = "6220072402";
        role = "visitor";
        navigate(`/login/${eventId}/${role}`);
        return;

    }
    return (
        <>
            <LanguageSwitcher position="fixed" />
            <ThemeToggleButton position="fixed" />
            <Grid
                container
                sx={{
                    minWidth: "100%",
                    height: "100vh",
                    backgroundImage: `url(${theme.palette.mode === "light" ? bgImagelight : bgImagedark})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    overflow: "scroll",
                    backgroundColor: theme.palette.background.default,
                    py: 4,
                }}
            >


                <Grid item md={6} sx={{ display: { xs: "none", md: "flex" } }}>
                    <img src={sidebardImage} alt="Bg" style={{ width: "100%", height: "100%" }} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} >
                    <Stack direction="column" justifyContent="center" gap={2} mb={2} alignItems="center">
                        <img
                            src={`${IMAGES_API_URL}logo/${eventId}.png`}
                            alt="Logo"
                            style={{ width: "250px", height: "150px", objectFit: "contain" }}
                        />
                    </Stack>

                    <Container maxWidth="md">
                        <Paper
                            sx={{
                                p: 3,
                                borderRadius: 7,
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: theme.shadows[1],
                            }}
                            variant="outlined"
                        >
                            <Typography
                                align="center"
                                variant="h5"
                                sx={{ color: theme.palette.text.primary, my: 2, fontWeight: 500 }}
                                gutterBottom
                            >
                                {t("LoginPanel")}
                            </Typography>
                            <LoginForm onSubmit={onLogin} eventIdParam={eventId} roleParam={role} />

                            {loading && <LinearProgress />}

                            {!!error && (
                                <motion.div
                                    animate={{
                                        x: [30, -30, 15, -15, 8, 0],
                                    }}
                                    transition={{ duration: 0.4 }}
                                >
                                    <Typography color="error" align="center" sx={{ mt: 2 }}>
                                        {error.message}
                                    </Typography>
                                </motion.div>
                            )}
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}


export function LoginForm({ onSubmit, eventIdParam, roleParam }) {
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm({
        defaultValues: {
            eventId: eventIdParam || "",
            role: roleParam || "visitor",
            email: "",
            password: "",
            rememberMe: false,
        },
    });
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Controller
                    control={control}
                    name="email"
                    rules={{ required: t("Required") }}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            inputRef={ref}
                            helperText={error?.message}
                            error={!!error}
                            label={t("Email/UserId")}
                            required
                            fullWidth
                            autoComplete="new-password"
                            color="success"
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="password"
                    rules={{ required: t("Required") }}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            inputRef={ref}
                            helperText={error?.message}
                            error={!!error}
                            label={t("Password")}
                            required
                            fullWidth
                            type="password"
                            color="success"
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="rememberMe"
                    render={({ field: { ref, ...field } }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} inputRef={ref} />}
                            label={t("Remember Me")}
                        />
                    )}
                />

                <LoadingButton
                    size="large"
                    variant="contained"
                    type="submit"
                    loading={formState.isSubmitting}
                    sx={{
                        background: theme.palette.primary.main,
                        "&:hover": {
                            background: theme.palette.primary.dark,
                        },
                    }}
                >
                    {t("Enter")}
                </LoadingButton>
            </Stack>

            <Typography align="center" sx={{ mt: 2 }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        console.log("Forgot Password");
                    }}
                >
                    {t("Forgot Password?")}
                </Link>
            </Typography>

            <Typography align="center" sx={{ mt: 2 }}>
                {t("Not registered?")}{" "}
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        navigate(`/register/${eventIdParam || "6220072402"}/${roleParam || "visitor"}`);
                    }}
                >
                    {t("Click here to signup")}
                </Link>
            </Typography>
        </form>
    );
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    eventIdParam: PropTypes.string,
    roleParam: PropTypes.string,
};
