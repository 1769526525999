import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useTheme } from '../contexts/ThemeContext';

const ThemeToggleButton = ({position}) => {
    const { mode, toggleTheme } = useTheme();
    const style = position === "relative" ? { position: "relative", backgroundColor: "rgba(0, 0, 0, 0)" } : { position: position, top: 0, right: 0, backgroundColor: "rgba(0, 0, 0, 0)" };
    return (
        <Box sx={style}>
                
        <IconButton onClick={toggleTheme} color="inherit">
            {mode === 'light' ? <Brightness4 sx={{fontSize:'30px'}} /> : <Brightness7 sx={{fontSize:'30px'}}/>}
        </IconButton>
        </Box>
    );
};

ThemeToggleButton.propTypes = {
    position: PropTypes.string.isRequired,
};

export default ThemeToggleButton;