import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
const LanguageSwitcher = (position) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang) => {
    setAnchorEl(null);
    if (lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
    }
  };
  return (
    <Box sx={ position === "fixed" ?  { borderRadius:"50px",position: position, m:2,p:2, top: 0, right: 40, backgroundColor: "rgba(0, 0, 0, 0)" }:{ borderRadius:"50px",position: position, backgroundColor: "rgba(0, 0, 0, 0)" } }>
      <IconButton onClick={handleClick} color="inherit">
        <TranslateIcon sx={{fontSize:'30px'}} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
        <MenuItem onClick={() => handleClose('ar')}>العربية</MenuItem>
      </Menu>
    </Box>
  );

};

export default LanguageSwitcher;