import React, { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { GetApp } from '@mui/icons-material';
import { IconButton, Box, Typography, CircularProgress } from '@mui/material';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { useTranslation } from 'react-i18next';
import useUserData from '../hooks/useUserData';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfIcon from '../Assets/Icons/pdf.png';
import jpgIcon from '../Assets/Icons/jpg.png';
import BackWithBreadcrumbAppbar from '../components/BackWithBreadcrumbAppbar';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 400;

const MyBadge = () => {
    const { t } = useTranslation();
    const { data } = useUserData();
    const parsedData = typeof (data?.me) === "string" ? JSON.parse(data?.me) : data?.me || {};
    const { eventId, role, id, name, eventName } = parsedData;
    // const pdfUrl = `https://api-reg.eventegration.com/graphql/badge/6220072402/visitor/4249235908`;
    const pdfUrl = `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${id}`;

    const [numPages, setNumPages] = useState(null);
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState(null);

    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleDownloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${name}_badge_${eventName}_${id}.pdf`;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const handleDownloadJPG = async () => {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 2.0 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({ canvasContext: context, viewport }).promise;

            const imgData = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = `${name}_badge_${eventName}-${pageNum}.jpg`;
            link.click();
        }
    };

    const links = [{ label: t("Dashboard"), href: "/" }];
    const currentPage = t("MyBadge");

    return (
        <>
            <BackWithBreadcrumbAppbar links={links} currentPage={currentPage} />
            <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    {name && `${name} ${t("MyBadge")}`}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <div ref={setContainerRef} style={{ width: '400px' }}>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={(error) => console.error('Error while loading document:', error)}
                            options={options}
                            loading={<Box sx={{ minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></Box>}
                            error={<Box sx={{ minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}><Typography color="error">{t("BadgeNotReady", { name })}</Typography></Box>}
                        >
                            {Array.from(new Array(numPages), (_el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                />
                            ))}
                        </Document>
                    </div>
                </Box>
                {numPages && (
                    <Box>
                        <IconButton onClick={handleDownloadPDF} sx={{ position: "relative" }} aria-label={t("DownloadAsPDF")}>
                            <img src={pdfIcon} alt="PDF Icon" style={{ width: 36, height: 36 }} />
                            <GetApp sx={{ position: "absolute", bottom: 0, right: 0, fontSize: 20 }} />
                        </IconButton>
                        <IconButton onClick={handleDownloadJPG} sx={{ position: "relative" }} aria-label={t("DownloadAsJPG")}>
                            <img src={jpgIcon} alt="JPG Icon" style={{ width: 36, height: 36 }} />
                            <GetApp sx={{ position: "absolute", bottom: 0, right: 0, fontSize: 20 }} />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default MyBadge;