import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Slide } from '@mui/material';
import PaymobBtns from '../PaymentFolder/Paymob';
import PaypalBtns from '../PaymentFolder/Paypal';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PaymentSummaryDialog({
    open,
    handleClose,
    price,
    selectedCurrency,
    clientId,
    handlePaymentSuccess,
    secretKey,
    publicKey,
    selectedPaymentOption,
    allData,
    base,
    setErrorInPaymentInitialization,
    setIsPopupNotOpened,
}) {
    // Determine screen size based on payment option
    const screenSize = (() => {
        if (selectedPaymentOption === "paypal" || allData?.paymentMethod === "paypal") {
            return "fullWidth";
        }
        return "fullScreen";
    })();

    // Render content based on conditions
    const renderDialogContent = () => {
        if (!clientId || !secretKey) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress color="secondary" size={28} />
                </Box>
            );
        }

        if (selectedPaymentOption === "paypal" || allData?.paymentMethod === "paypal") {
            return (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Detail
                                    </TableCell>
                                    <TableCell align="right">{allData?.payment}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Payable Amount
                                    </TableCell>
                                    <TableCell align="right">
                                        {price} {selectedCurrency}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaypalBtns
                        currency={selectedCurrency}
                        amount={price}
                        onPaymentSuccess={handlePaymentSuccess}
                        buyerEmail={allData?.Email || allData?.email}
                        clientId={clientId}
                        description={allData?.payment}
                        base={base}
                    />
                </>
            );
        }

        if (selectedPaymentOption === "paymob" || allData?.paymentMethod === "paymob") {
            return (
                <PaymobBtns
                    currency={selectedCurrency}
                    amount={price}
                    onPaymentSuccess={handlePaymentSuccess}
                    buyerEmail={allData?.Email || allData?.email}
                    clientId={clientId}
                    description={allData?.payment}
                    base={base}
                    setErrorInPaymentInitialization={setErrorInPaymentInitialization}
                    setIsPopupNotOpened={setIsPopupNotOpened}
                    secretKey={secretKey}
                    publicKey={publicKey}
                    allData={allData}
                />
            );
        }

        return null;
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullScreen={screenSize === "fullScreen"}
            fullWidth={screenSize === "fullWidth"}
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            BackdropProps={{
                style: {
                    pointerEvents: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                },
            }}
            PaperProps={{
                style: {
                    maxWidth: "100%",
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                {renderDialogContent()}
            </DialogContent>
            {(selectedPaymentOption === "paypal" || allData?.paymentMethod === "paypal") && (
                <DialogActions>
                    <Button color="error" variant="contained" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
PaymentSummaryDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    price: PropTypes.number,
    selectedCurrency: PropTypes.string,
    clientId: PropTypes.string,
    handlePaymentSuccess: PropTypes.func,
    secretKey: PropTypes.string,
    publicKey: PropTypes.string,
    selectedPaymentOption: PropTypes.string,
    allData: PropTypes.shape({
        paymentMethod: PropTypes.string,
        payment: PropTypes.string,
        Email: PropTypes.string,
        email: PropTypes.string,
    }),
    base: PropTypes.string,
    setErrorInPaymentInitialization: PropTypes.func,
    setIsPopupNotOpened: PropTypes.func,
};
export default PaymentSummaryDialog;