import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, useTheme, useMediaQuery, Box, Drawer, List, ListItem, ListItemText, Avatar, Divider, ListItemButton } from '@mui/material';
import ThemeToggleButton from '../components/ThemeToggleButton';
import { IMAGES_API_URL } from '../constant';
import useUserData from '../hooks/useUserData';
import PropTypes from 'prop-types';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Layout = () => {
  const navigate = useNavigate();
  const { data } = useUserData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { t } = useTranslation();

  let me = data?.me;
  if (typeof (data?.me) === "string") (me = JSON.parse(data?.me));
  console.log(me);

  const handleLogout = () => {
    localStorage.removeItem('auth.app.eventegration.com');
    navigate(`/login/${me?.eventId}/${me?.role}`);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {isMobile ? (
            <>
              <Box sx={{ flexGrow: 0, textAlign: 'left',p: 1 }}>
                <img src={`${IMAGES_API_URL}logo/${me?.eventId}.png`} alt="Logo" style={{ height: '80px', width:"100%", objectFit:"contain" }} />
              </Box>
              <DrawerComponent anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} me={me} handleLogout={handleLogout} navigate={navigate} />
              <Box sx={{ flexGrow: 1 }} />
              <LanguageSwitcher position="relative" />
              <ThemeToggleButton position="relative"/>
              <IconButton onClick={toggleDrawer(true)} color="inherit">
                <Avatar alt={me?.name.toUpperCase()} src="/path/to/profile-pic.jpg" />
              </IconButton>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, p: 1 }} >
                <button onClick={() => navigate('/')} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                  <img src={`${IMAGES_API_URL}logo/${me?.eventId}.png`} alt="Logo" style={{ height: '80px', width: "100%", objectFit: "contain" }} />
                </button>
              </Box>
              <DrawerComponent anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} me={me} handleLogout={handleLogout} navigate={navigate} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LanguageSwitcher position="relative"/>
                <ThemeToggleButton position="relative"/>
                <IconButton onClick={toggleDrawer(true)} color="inherit">
                  <Avatar alt={me?.name.toUpperCase()} src="/path/to/profile-pic.jpg" />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Outlet />
      <Box sx={{ backgroundColor: theme.palette.background.default, p: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
        {t("PoweredBy")}
        </Typography>
      </Box>
    </>
  );
};

const DrawerComponent = ({ open, onClose, me, handleLogout, navigate }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
    <Button onClick={onClose} onKeyDown={onClose} style={{ padding: 0 }}>
      <img
        src={`${IMAGES_API_URL}logo/${me?.eventId}.png`}
        alt="Event Logo"
        style={{ width: 220, padding: 10, margin: 'auto', display: 'block', cursor: 'pointer' }}
      />
    </Button>
    <Divider />
    <List>
      <ListItem>
        <Avatar alt={me?.name.toUpperCase()} src="/path/to/profile-pic.jpg" sx={{ mr: 1 }} />
        <ListItemText primary={me?.name.toUpperCase()} secondary={me?.email} />
      </ListItem>
      <Divider />
      <ListItemButton onClick={() => navigate('/')} sx={{ backgroundColor: location.pathname === '/' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}>
        <ListItemText primary={t("Home")} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/contact')} sx={{ backgroundColor: location.pathname === '/contact' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}>
        <ListItemText primary={t("Contact")} />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemText primary={t("Logout")} />
      </ListItemButton>
    </List>
  </Drawer>
  );
};

DrawerComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  me: PropTypes.object,
  handleLogout: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Layout;