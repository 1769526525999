import { useQuery, gql } from '@apollo/client';

const GET_USER_DATA = gql`
  query {
    me
  }
`;

const useUserData = () => {
  const { data, loading, error } = useQuery(GET_USER_DATA, {
    onError: (e) => console.log(JSON.stringify(e, null, 2)),
  });
  if(error){
    console.log(error);
  }
  if(loading){
    console.log(loading);
  }
  if(typeof(data)==="string"){
    const parseData = JSON.parse(data.me);
    console.log(parseData);
    return { data: parseData };
  }

  return { data};
};

export default useUserData;