import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';

const PaymentProcessComponent = ({ message }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography
                    variant="h5"
                    color={"black"}
                    sx={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                    {message}
                </Typography>
                 <CircularProgress color="warning" size={36} />
            </Stack>
        </Box>
    );
};

PaymentProcessComponent.propTypes = {
    message: PropTypes.string.isRequired,
};

export default PaymentProcessComponent;