import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormLabel,
    FormGroup,
    Grid,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    Typography,
    TextField,
    MenuItem,
    Autocomplete,
    Box,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import countries from "../countries.json";

const handleEmailChange = (e, rhf, setEmail) => {
    const newEmail = e.target.value;
    rhf.field.onChange(newEmail);
    setEmail(newEmail);
};

const renderMCQField = (f, rhf, showFamilyMembers, errorMessage) => (
    <FormControl
        fullWidth
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
    >
        <FormLabel>{f.label || f.name}</FormLabel>
        <FormGroup>
            <Grid container spacing={1}>
                {f.options
                    ?.map((op) => op.trim())
                    ?.map((op, i) => (
                        <Grid item xs={f.xs || 12} md={f.md || 6} key={`${op}-${i}`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={op}
                                        checked={rhf.field.value?.includes(op)}
                                        onChange={(e) => {
                                            const arr = rhf.field.value || [];
                                            if (e.target.checked) {
                                                arr.push(op);
                                            } else if (arr.includes(op)) {
                                                arr.splice(arr.indexOf(op), 1);
                                            }
                                            rhf.field.onChange(arr);
                                        }}
                                    />
                                }
                                label={
                                    op === "$Other" ? (
                                        "Other:"
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: op,
                                            }}
                                        />
                                    )
                                }
                            />
                        </Grid>
                    ))}
            </Grid>
        </FormGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
);

const renderRadioField = (f, rhf, showFamilyMembers, errorMessage) => (
    <FormControl
        fullWidth
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
    >
        <FormLabel>{f.label || f.name}</FormLabel>
        <RadioGroup
            name={f.name}
            onChange={rhf.field.onChange}
            onBlur={rhf.field.onBlur}
            value={rhf.field.value}
        >
            <Grid container spacing={1}>
                {f.options?.map((op, i) => (
                    <Grid item xs={f.xs || 12} md={f.md || 6} key={`${op}-${i}`}>
                        <FormControlLabel
                            control={<Radio />}
                            label={op === "$Other" ? "Other:" : op}
                            value={op}
                        />
                    </Grid>
                ))}
            </Grid>
        </RadioGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
);

const renderHTMLField = (f, showFamilyMembers) => (
    <Typography
        variant="body1"
        sx={showFamilyMembers ? {} : f.sx || {}}
        dangerouslySetInnerHTML={{ __html: f.defaultValue }}
    />
);

const renderPaymentField = (f, rhf, pmt, showFamilyMembers, errorMessage) => {
    if (pmt && pmt.amount === 0) {
        return null;
    }
    return (
        <FormControl
            fullWidth
            required={f.required}
            sx={showFamilyMembers ? {} : f.sx || {}}
            error={!!rhf.fieldState.error}
        >
            <FormLabel>{f.label || f.name}</FormLabel>
            <RadioGroup
                name={f.name}
                onChange={rhf.field.onChange}
                onBlur={rhf.field.onBlur}
                value={rhf.field.value}
            >
                <Grid container spacing={1}>
                    {pmt && pmt.amount > 0 ? (
                        <Grid item xs={f.xs || 12} md={f.md || 6}>
                            <FormControlLabel
                                control={<Radio />}
                                label={"Amount"}
                                value={pmt.amount}
                            />
                        </Grid>
                    ) : (
                        f.options?.map((op, i) => (
                            <Grid item xs={f.xs || 12} md={f.md || 6} key={`${op}-${i}`}>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={op === "$Other" ? "Other:" : op}
                                    value={op}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </RadioGroup>
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

const renderCheckboxField = (f, rhf, showFamilyMembers, errorMessage) => (
    <FormControl
        required={f.required}
        fullWidth
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
    >
        <FormControlLabel
            onChange={rhf.field.onChange}
            onBlur={rhf.field.onBlur}
            inputRef={rhf.field.ref}
            control={<Checkbox />}
            label={f.label || f.name}
            name={rhf.field.name}
            checked={rhf.field.value}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
);

const renderSelectField = (f, rhf, showFamilyMembers, setValue, errorMessage) => {
    if (f.sx?.display === "none" && !showFamilyMembers) {
        setValue(rhf.field.name, "");
    }
    return (
        <TextField
            name={rhf.field.name}
            value={rhf.field.value}
            onChange={rhf.field.onChange}
            onBlur={rhf.field.onBlur}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error}
            helperText={errorMessage}
            label={f.label || f.name}
            required={f.required}
            sx={showFamilyMembers ? {} : f.sx || {}}
            fullWidth
            select
        >
            <MenuItem value="">None</MenuItem>
            {f.options.map((op) => (
                <MenuItem key={op} value={op}>
                    {op === "$Other" ? "Other:" : op}
                </MenuItem>
            ))}
        </TextField>
    );
};

const renderCountryField = ({
    f,
    rhf,
    selectedCountry,
    setSelectedCountry,
    selectedMobileCode,
    setSelectedMobileCode,
    setValue,
    showFamilyMembers,
    errorMessage
}) => {
    const countryIsd = countries.find((c) => c.label === selectedCountry);

    let selectedCountryValue;
    if (!rhf.field.value) {
        selectedCountryValue = countries.find((c) => c.label === countryIsd?.label);
    } else if (!selectedCountry) {
        selectedCountryValue = countries.find((c) => c.label === rhf.field.value);
    } else {
        selectedCountryValue = countries.find(
            (c) => c.label === countryIsd?.label || c.label === rhf.field.value
        );
    }

    return (
        <Autocomplete
            options={countries}
            autoHighlight
            value={selectedCountryValue}
            onChange={(e, v) => {
                rhf.field.onChange(v?.label || "");
                setValue("calling_code", selectedMobileCode || v?.dial_code || "");
                setSelectedCountry(v?.label);
                setSelectedMobileCode(v?.dial_code || "");
            }}
            onBlur={rhf.field.onBlur}
            getOptionLabel={(o) => o?.label || ""}
            renderOption={(props, option) => (
                <Box
                key={option?.label}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${countries
                            .find((c) => c.label === option?.label)
                            .code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${countries
                            .find((c) => c.label === option?.label)
                            .code.toLowerCase()}.png`}
                        alt=""
                    />
                    {option?.label} ({option?.code}) {option?.dial_code}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    name={rhf.field.name}
                    inputRef={rhf.field.ref}
                    error={!!rhf.fieldState.error}
                    helperText={errorMessage}
                    label={f.label || f.name}
                    placeholder={f.placeholder}
                    required={f.required}
                    sx={showFamilyMembers ? {} : f.sx || {}}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${!selectedCountry
                                        ? "om"
                                        : countryIsd?.code?.toLowerCase() || "om"
                                        }.png`}
                                    alt="country_flag"
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

const renderMobileCodeField = (f, rhf, findCountryCodeByDialCode, selectedMobileCode, setSelectedMobileCode, showFamilyMembers, errorMessage) => {
    const countryName = findCountryCodeByDialCode(rhf.field.value);
    const countryIsd = selectedMobileCode
        ? countries.find((c) => c.dial_code === selectedMobileCode)
        : countries.find((c) => c.label === countryName);

    let selectedCountryValue;
    if (!rhf.field.value) {
        selectedCountryValue = countries.find(
            (c) => c.dial_code === countryIsd.dial_code || c.dial_code === "+968"
        );
    } else if (!selectedMobileCode) {
        selectedCountryValue = countries.find((c) => c.dial_code === rhf.field.value);
    } else {
        selectedCountryValue = countries.find((c) => c.dial_code === countryIsd.dial_code);
    }

    return (
        <Autocomplete
            options={countries}
            autoHighlight
            value={selectedCountryValue}
            onChange={(e, v) => {
                rhf.field.onChange(v?.dial_code || "");
                setSelectedMobileCode(v?.dial_code || "");
            }}
            onBlur={rhf.field.onBlur}
            getOptionLabel={(o) => o?.dial_code || ""}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <img
                        loading="lazy"
                        width="30"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {option.dial_code}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    name={rhf.field.name}
                    inputRef={rhf.field.ref}
                    error={!!rhf.fieldState.error}
                    helperText={errorMessage}
                    label={f.label || f.name}
                    placeholder={f.placeholder}
                    required={f.required}
                    sx={showFamilyMembers ? {} : f.sx || {}}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${countryIsd?.code?.toLocaleLowerCase()}.png`}
                                    alt="country_flag"
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

const renderEmailField = ({ f, rhf, handleEmailChange, emailStatus, showFamilyMembers, setEmail }) => {
    const { isEmailTaken, emailCheckLoading, errorMessage } = emailStatus;
    const autoCompleteValue = f.autoComplete || f.type === "confirm_email" ? "new-password" : "on";
    let helperTextValue;
    if (emailCheckLoading) {
        helperTextValue = "Checking email...";
    } else if (isEmailTaken) {
        helperTextValue = "This email is already registered";
    } else {
        helperTextValue = errorMessage;
    }    


    return (
        <TextField
            type="email"
            InputProps={{
                autoComplete: autoCompleteValue,
                readOnly: f.readonly,
            }}
            name={rhf.field.name}
            value={rhf.field.value}
            onChange={(e) => handleEmailChange(e, rhf, setEmail)}
            onBlur={rhf.field.onBlur}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error || isEmailTaken}
            helperText={helperTextValue}
            label={f.label || f.name}
            placeholder={f.placeholder}
            required={f.required}
            sx={showFamilyMembers ? {} : f.sx || {}}
            fullWidth
        />
    );
};

const renderDefaultField = (f, rhf, showFamilyMembers, errorMessage) => (
    <TextField
        type={f.type === "number" ? "number" : "text"}
        InputProps={{
            autoComplete:
                f.autoComplete || f.type === "confirm_email"
                    ? "new-password"
                    : "on",
            readOnly: f.readonly,
        }}
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={rhf.field.onChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error}
        helperText={errorMessage}
        label={f.label || f.name}
        placeholder={f.placeholder}
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        fullWidth
    />
);

function MyField({
    rhf,
    f,
    setValue,
    findCountryCodeByDialCode,
    selectedCountry,
    setSelectedCountry,
    selectedMobileCode,
    setSelectedMobileCode,
    parsedRegistration,
    pmt,
    setEmail,
    isEmailTaken,
    emailCheckLoading,
    showFamilyMembers,
}) {
    const errorMessage = rhf.fieldState.error?.message;

    if (f.name === "user_id" && parsedRegistration && parsedRegistration.id)
        setValue("user_id", parsedRegistration?.id);
    if (f.name === "Email" && parsedRegistration && parsedRegistration.Email)
        setValue("Email", parsedRegistration?.Email);
    
    React.useEffect(() => {
        if (f.sx?.display === "none" && !showFamilyMembers) {
            setValue(rhf.field.name, "");
        }
    }, [f.sx, showFamilyMembers, setValue, rhf.field.name]);
    const emailStatus = { isEmailTaken, emailCheckLoading, errorMessage };

    switch (f.type) {
        case "mcq":
            return renderMCQField(f, rhf, showFamilyMembers, errorMessage);
        case "radio":
            return renderRadioField(f, rhf, showFamilyMembers, errorMessage);
        case "html":
            return renderHTMLField(f, showFamilyMembers);
        case "payment":
            return renderPaymentField(f, rhf, pmt, showFamilyMembers, errorMessage);
        case "checkbox":
            return renderCheckboxField(f, rhf, showFamilyMembers, errorMessage);
        case "select":
            return renderSelectField(f, rhf, showFamilyMembers, setValue, errorMessage);
        case "country":
            return renderCountryField({
                f,
                rhf,
                selectedCountry,
                setSelectedCountry,
                selectedMobileCode,
                setSelectedMobileCode,
                setValue,
                showFamilyMembers,
                errorMessage
            });
        case "mobile_code":
        case "calling_code":
            return renderMobileCodeField(f, rhf, findCountryCodeByDialCode, selectedMobileCode, setSelectedMobileCode, showFamilyMembers, errorMessage);
        case "email":
            return renderEmailField({ f, rhf, handleEmailChange, emailStatus, showFamilyMembers, setEmail });
        default:
            return renderDefaultField(f, rhf, showFamilyMembers, errorMessage);
    }
}


MyField.propTypes = {
    rhf: PropTypes.object.isRequired,
    f: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string,
        sx: PropTypes.object,
        xs: PropTypes.number,
        md: PropTypes.number,
        placeholder: PropTypes.string,
        options: PropTypes.array,
        defaultValue: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        pattern: PropTypes.string,
        required: PropTypes.bool,
        readonly: PropTypes.bool,
        autoComplete: PropTypes.string,
    }).isRequired,
    setValue: PropTypes.func.isRequired,
    findCountryCodeByDialCode: PropTypes.func.isRequired,
    selectedCountry: PropTypes.string,
    setSelectedCountry: PropTypes.func.isRequired,
    selectedMobileCode: PropTypes.string,
    setSelectedMobileCode: PropTypes.func.isRequired,
    parsedRegistration: PropTypes.object,
    pmt: PropTypes.object,
    setEmail: PropTypes.func.isRequired,
    isEmailTaken: PropTypes.bool,
    emailCheckLoading: PropTypes.bool,
    showFamilyMembers: PropTypes.bool,
};

export default MyField;