import React from 'react';
import PropTypes from 'prop-types';
import { Container, Paper, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SponsorLinkError = ({ pmtError }) => {
    return (
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 8 }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main", mb: 2 }} />
                <Typography variant="h4" gutterBottom>
                    {pmtError.message || "Sponsor Link Not working"}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={() => window.location.reload()}
                >
                    Retry
                </Button>
            </Paper>
        </Container>
    );
};

SponsorLinkError.propTypes = {
    pmtError: PropTypes.shape({
        message: PropTypes.string
    })
};

export default SponsorLinkError;