// export const API_URL="http://api-reg.eventegration.com/graphql";
export const IMAGES_API_URL = "https://api-admin.eventegration.com/public/";
export const API_URL = "https://api-reg-eventegration-com.vercel.app/graphql";
// export const API_URL = "http://localhost:4005/graphql";
// export const IMAGES_API_URL = "http://localhost:4000/";

export const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};