import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';

const LimitExhausted = ({ pmt }) => {
    return (
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 8 }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                <ErrorOutlineIcon
                    sx={{ fontSize: 60, color: "warning.main", mb: 2 }}
                />
                <Typography variant="h4" gutterBottom>
                    Registration Limit Reached
                </Typography>
                <Typography variant="body1" paragraph>
                    We apologize, but the registration limit for this sponsor's link has
                    been reached.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {pmt.company ? `${pmt.company} has` : "The sponsor has"} exhausted
                    the total allowed registrations for this link.
                </Typography>
                {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    href={`/${pmt.link}`}
                >
                    Return to Homepage
                </Button> */}
            </Paper>
        </Container>
    );
};
LimitExhausted.propTypes = {
    pmt: PropTypes.shape({
        company: PropTypes.string,
        link: PropTypes.string.isRequired,
    }).isRequired,
};

export default LimitExhausted;