import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { IconButton, Box, Typography, CircularProgress, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfIcon from '../Assets/Icons/pdf.png';
import BackWithBreadcrumbAppbar from '../components/BackWithBreadcrumbAppbar';
import useUserData from '../hooks/useUserData';
import floorplan from '../Assets/floorplan.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const FloorPlan = () => {
  const { data } = useUserData();
  const parsedData = typeof data?.me === 'string' ? JSON.parse(data?.me) : data?.me || {};
  const { eventId, eventName } = parsedData;

  // const pdfUrl = `https://api-admin.eventegration.com/public/floorPlan/${eventId}/${eventId}.pdf`;
  const pdfUrl = floorplan;

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.2, 3.0));
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setOffsetX((prevX) => prevX + e.clientX - lastPosition.x);
    setOffsetY((prevY) => prevY + e.clientY - lastPosition.y);
    setLastPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => setIsDragging(false);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    const touch = e.touches[0];
    setLastPosition({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const touch = e.touches[0];
    setOffsetX((prevX) => prevX + touch.clientX - lastPosition.x);
    setOffsetY((prevY) => prevY + touch.clientY - lastPosition.y);
    setLastPosition({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = () => setIsDragging(false);

  const handleDownloadPDF = async () => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `floorPlan_${eventId}.pdf`;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <>
      <BackWithBreadcrumbAppbar
        links={[{ label: 'Dashboard', href: '/' }]}
        currentPage="FloorPlan"
      />
      <Box sx={{ p: 3, minHeight: '80vh' }}>
        <Typography variant="h4" align="center" gutterBottom>
          {eventName || 'Event'} Floor Plan
        </Typography>
        <Box height={10} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            border: '1px solid #ddd',
            borderRadius: '8px',
            position: 'relative',
            height: '80vh',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            style={{
              transform: `scale(${scale}) translate(${offsetX / scale}px, ${offsetY / scale}px)`,
              transformOrigin: 'top left',
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          >
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<CircularProgress />}
            >
              {Array.from(new Array(numPages), (_el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Tooltip title="Zoom In">
            <IconButton onClick={handleZoomIn} color="primary">
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <IconButton onClick={handleZoomOut} color="primary">
              <Remove />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download as PDF">
            <IconButton onClick={handleDownloadPDF} color="primary">
              <img src={pdfIcon} alt="PDF Icon" style={{ width: 24, height: 24 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default FloorPlan;
