import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      submit: "Submit",
      alreadyRegistered: "Already registered?",
      clickHereToLogin: "Click here to login",
      notRegistered: "Not registered?",
      clickHereToRegister: "Click here to register",
      myBadge: "My Badge",
      floorPlan: "Floor Plan",
      attendees: "Attendees",
      exhibitors: "Exhibitors",
      products: "Products",
      conferences: "Conferences",
      networking: "Networking",
      myAgenda: "My Agenda",
      sponsors: "Sponsors",
      eventName: "Event Name",
      "Email/UserId": "Email/UserId",
      "Password": "Password",
      "Remember Me": "Remember Me",
      "Enter": "Enter",
      "Forgot Password?": "Forgot Password?",
      "Not registered?": "Not registered?",
      "Click here to signup": "Click here to signup",
      "Required": "Required",
      "Dashboard": "Dashboard",
      "MyBadge": "My Badge",
      "BadgeNotReady": "Sorry {{name}}, your badge is not ready yet. Please try again later.",
      "DownloadAsPDF": "Download as PDF",
      "DownloadAsJPG": "Download as JPG",
      "PoweredBy": "Powered by Eventegration",
      "LoginPanel": "Login Panel",
      "Back": "Back",
      "Home": "Home",
      "Contact": "Contact",
      "FloorPlan": "FloorPlan"
    }
  },
  ar: {
    translation: {
      submit: "إرسال",
      alreadyRegistered: "مسجل بالفعل؟",
      clickHereToLogin: "انقر هنا لتسجيل الدخول",
      notRegistered: "غير مسجل؟",
      clickHereToRegister: "اضغط هنا للتسجيل",
      myBadge: "شهادتي",
      floorPlan: "خريطة الطابق",
      attendees: "الحضور",
      exhibitors: "العارضين",
      products: "المنتجات",
      conferences: "المؤتمرات",
      networking: "التواصل",
      myAgenda: "جدولي",
      sponsors: "الرعاة",
      eventName: "اسم الحدث",
      "Email/UserId": "البريد الإلكتروني / اسم المستخدم",
      "Password": "كلمة المرور",
      "Remember Me": "تذكرني",
      "Enter": "تسجيل الدخول",
      "Forgot Password?": "نسيت كلمة المرور؟",
      "Not registered?": "غير مسجل؟",
      "Click here to signup": "اضغط هنا للتسجيل",
      "Required": "مطلوب",
      "Dashboard": "لوحة القيادة",
      "MyBadge": "شاراتي",
      "BadgeNotReady": "عذراً {{name}}، شارتك غير جاهزة بعد. يرجى المحاولة مرة أخرى لاحقاً.",
      "DownloadAsPDF": "تحميل كملف PDF",
      "DownloadAsJPG": "تحميل كملف JPG",
      "PoweredBy": "مشغل بواسطة Eventegration",
      "LoginPanel": "لوحة تسجيل الدخول",
      "Back": "رجوع",
      "Home": "الصفحة الرئيسية",
      "Contact": "اتصل",
      "FloorPlan": "مخطط الطابق"


    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;