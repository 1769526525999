import { setLengthValidation } from "./setLengthValidation";
import { setPatternValidation } from "./setPatternValidation";
import { setRequiredValidation } from "./setRequiredValidation";
import { setTypeValidation } from "./setTypeValidation";
import * as yup from "yup";

export const buildValidation = (f) => {
    let v = yup;
    v = setTypeValidation(v, f);
    v = setRequiredValidation(v, f);
    v = setLengthValidation(v, f);
    v = setPatternValidation(v, f);
    return v;
};