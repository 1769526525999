export const setLengthValidation = (v, f) => {
    if (f.maxLength && !["checkbox", "radio", "payment", "select", "mcq"].includes(f.type)) {
        v = v.max(
            f.maxLength,
            f.type === "number"
                ? `Max value: ${f.maxLength}`
                : `Max ${f.maxLength} characters allowed`
        );
    }
    if (f.minLength && !["checkbox", "radio", "payment", "select", "mcq"].includes(f.type)) {
        v = v.min(
            f.minLength,
            f.type === "number"
                ? `Min value: ${f.minLength}`
                : `Min ${f.minLength} characters required`
        );
    }
    return v;
};