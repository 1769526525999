import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#2A4B50', // New Primary Color
        },
        secondary: {
            main: '#1AA98B', // New Secondary Color
        },
        background: {
            default: '#e0f7fa', // Light Blue (Water)
            paper: '#ffffff', // White
        },
        text: {
            primary: '#2A4B50', // New Primary Color
            secondary: '#1AA98B', // New Secondary Color
            white: '#fff', // New Secondary Color
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    background: 'linear-gradient(45deg, #2A4B50 30%, #1AA98B 90%)',
                    color: '#ffffff', // White
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                        background: 'linear-gradient(45deg, #223D42 30%, #178A72 90%)',
                        transform: 'scale(1.05)',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(45deg, #2A4B50 30%, #1AA98B 90%)',
                    color: '#ffffff', // White
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#e0f7fa', // Light Blue (Water)
                    color: '#2A4B50', // New Primary Color
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#2A4B50', // New Primary Color
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // White
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2A4B50', // New Primary Color
                    color: '#ffffff', // White
                },
            },
        },
    },
});

export default lightTheme;